<template>
    <h2> Clinical Healthcare Services </h2>

    <p>
        We strive to provide a full range of Family Medicine and Chiropractic services. 
        We believe in health promotion and disease prevention when possible but provide 
        patient centered management of chronic illnesses. We also provide acute care services 
        with same day appointments as well as nursing home and hospital care. We have been 
        using one of the top five rated outpatient electronic medical records since 2003. 
    </p>

    <Panel header="Preventative Services">
        <ul class="servicesList twoColumns">
            <li> ICC/School Bus Driver (DOT) Physicals </li>
            <li> Well Child Exams/Childhood Immunizations </li>
            <li> Kindergarten Physicals </li>
            <li> School/Sports Physicals </li>
            <li> Adult and College Immunizations </li>
            <li> Flu Immunizations (No Appointment Needed) </li>
            <li> Welcome to Medicare Exams </li>
            <li> Medicare Preventative Services (Initial and Subsequent) </li>
            <li> Contraception and Sexually Transmitted Disease Exams/Treatment/Counseling </li>
            <li> Pap Smear, Endometrial Biopsy </li>
            <li> Drug Screening/DOT Drug Collections </li>
            <li> Preoperative Examinations and Risk Assessment </li>
            <li> Upper Endoscopy and Colonoscopy </li>
        </ul>
    </Panel>
    <Panel header="Routine Services">
        <ul class="servicesList twoColumns">
            <li> Prenatal and Complete Obstetric Care including Cesarean Sections </li>
            <li> Laceration Repair </li>
            <li> Skin Lesion (including Warts) - Biopsy/Removal/Destruction/Cryotherapy </li>
            <li> Cerumen (Ear Wax) Removal </li>
            <li> Foot Care (Toenail care/Trimming)</li>
            <li> ImPACT Assessment/Concussion Assessment </li>
            <li> Allergy Shots </li>
            <li> Joint Injections-Steroid, Hyaluronic Acid </li>
            <li> Fracture Care (Splints or Casts) including Digital X-ray  </li>
            <li> Chronic Disease Management s.a. Diabetes, Coronary Artery Disease, Hypercholesterolemia,
                    Alzheimer's Disease, Atrial Fibrilation, Hypertension, Nursing Home Care, COPD,
                    Asthma Management, Rheumatoid and Osteoarthritis Management </li>
        </ul>
    </Panel>
    <Panel header="Lab Services">
        <ul class="servicesList">
            <li> In-House Waved Lab Include:  Strep Screen, Mono Screen, Urinalysis, Hemaglobin A1c,
                    Blood Glucose, Hemaglobin/Hematocrit, INR/Protime, Influenza, RSV </li>
            <li> Consultant Lab Through LabLink Inc. (Lincoln NE) with twice daily pickup. Most results in 24 hours. </li>
        </ul>
    </Panel>
    <Panel header="Preventative Services">
        <ul class="servicesList">
            <li>   Full Chiropractic Spinal Adjustments </li>
            <li>   Active Release Technique for Chronic Muscle and Tendon Injuries </li>
            <li>   Adult Preventative Medical Services </li>
            <li>   Well Child Care with Immunizations </li>
            <li>   Rehab For Musculoskeletal Injuries </li>
            <li>   Ultrasound, hydrotherapy, and Laser (Light) Therapy </li>
            <li>   Digital X-ray Imaging for Adult and Child Evaluations </li>
        </ul>
    </Panel>


</template>

<script>
import Panel from 'primevue/panel';

export default {
    name: 'Services',
    components: {
        Panel
    }
}
</script>

<style scoped>
ul {
    text-align: left;
}
.twoColumns {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

/* TODO: less than 594px do single column 
    Doesn't appear to work... */
@media screen and (max-width: 594px) {
   .twoColumns {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    }
}
</style>